body {
  background-color: #F8F8F8 !important;
  height: 100vh;
}

.body-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 120px;
  background: #2377A4;
  z-index: -1;
}

.body-header-line {
  position: absolute;
  left: 0;
  top: 120px;
  width: 100%;
  height: 5px;
  background: #124f96;
  z-index: -1;
}

.title-col{
  text-align: center;
}

.selector-col{

}

.book-col{
  margin-top: 20px !important;
}

.order-col{
  margin-top: 20px !important;
}

.size-bar {
  height: 100%;
  position: absolute;
  top: 0;
}

.vertical-ask-table {
  margin-bottom: 0px !important;
}

.vertical-book-separator {
  border-bottom: 2px solid #111111 !important;
}

.execution-row {
  outline: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}

.execution-row td{
  padding: 0.75rem;
}